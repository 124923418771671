import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogListing } from "../components/blog-listing"

const PageBlogListing = ({ data, pageContext }) => {
  const { craft } = data
  const { pageInfo, totalCount } = pageContext
  const { entries, categories } = craft
  const category = categories[0]
  return (
    <Layout>
      <SEO title={`${category.title} Blog`} />
      <BlogListing
        entries={entries}
        category={category}
        pageInfo={pageInfo}
        totalCount={totalCount}
      />
    </Layout>
  )
}

export const query = graphql`
  query BlogListingQuery(
    $categoryIds: [Craft_QueryParameter]!
    $relatedTo: [Int]!
    $site: [String]!
    $section: [String]!
    $limit: Int!
    $offset: Int!
  ) {
    craft {
      categories(id: $categoryIds) {
        id
        slug
        title
      }
      entries(
        relatedTo: $relatedTo
        site: $site
        section: $section
        limit: $limit
        offset: $offset
      ) {
        id
        title
        slug
        uri
        ...BlogPost
      }
    }
  }
`

export default PageBlogListing
